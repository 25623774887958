.footer {
  flex-direction: row;
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: var(--maxWidth);
  margin: 0 auto;

  .links {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: var(--u2);
    flex-wrap: wrap;
    padding: 0;

    a {
      color: var(--primary);
      font-weight: 600;
      text-decoration: none;
    }
  }
}

@media (max-width: 769px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .links {
      justify-content: center;
      gap: var(--u2);

      a {
        width: 100%;
      }
    }
  }
}
