.operationsSelector {
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: var(--u2);
  padding-right: var(--u3);

  .section {
    display: flex;
    flex-direction: column;
    gap: var(--u);
    .title {
      margin: 0;
      padding: 0;
      font-size: var(--font-16);
      text-align: center;
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: var(--u);
      text-align: center;
      color: var(--primary);
      font-weight: 600;

      .action {
        cursor: pointer;
        padding: var(--u) var(--u2);
      }

      .activeAction {
        @extend .action;
        background: var(--primary-50);
      }
    }
  }
}
